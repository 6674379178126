import React from "react";
import CompareDamage from "./CompareDamage"; // or wherever your file is

function App() {
  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      {/* <h1>PSU Damage Calculator</h1> */}
      <CompareDamage />
    </div>
  );
}

export default App;
