import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function Home(props) {
  const [post, setPost] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/`)
      .then((response) => response.json())
      .then((post) => {
        console.log(post[0].total_players);
        console.log(post);
        if (post[0].run_validation == null) {
          let postData = (
            <div className="home-app">
        <h3 className="home-header">PSUC Time Attack</h3>
        <div className="home-description">
        Welcome to PSUC Time Attack, a website that keeps track of speed runs for the <i>Phantasy Star Universe: Clementine</i> private server. Upload your runs in the <a href="https://discord.com/channels/164814706438045696/1074803836998402051">#ta-flex</a> channel in the <a href="https://discord.gg/psuclementine">Clementine Discord</a> to submit them to be added to the leaderboards!
        </div>
        <div className="totalRunsandPlayers">
        <div>Total Players: {post[0].total_players}</div>
        <div>Total Runs: {post[0].total_runs}</div>
        </div>
    </div>
          );
          setPost(postData);
          }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const [post2, setPost2] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/top_runs`)
      .then((response) => response.json())
      .then((post2) => {
        console.log(post2[0].total_players);
        console.log(post2);
        if (post2[0].run_validation == null) {
          let postData2 = (
            <div className="App">
        <div className="home-list">
          Most Ran Missions
          <ol className="list">
          <li><Link to={`/missions/solo/${post2[0].mission_name}`}>{post2[0].mission_name}</Link>: {post2[0].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[1].mission_name}`}>{post2[1].mission_name}</Link>: {post2[1].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[2].mission_name}`}>{post2[2].mission_name}</Link>: {post2[2].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[3].mission_name}`}>{post2[3].mission_name}</Link>: {post2[3].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[4].mission_name}`}>{post2[4].mission_name}</Link>: {post2[4].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[5].mission_name}`}>{post2[5].mission_name}</Link>: {post2[5].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[6].mission_name}`}>{post2[6].mission_name}</Link>: {post2[6].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[7].mission_name}`}>{post2[7].mission_name}</Link>: {post2[7].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[8].mission_name}`}>{post2[8].mission_name}</Link>: {post2[8].total_runs}</li>
          <li><Link to={`/missions/solo/${post2[9].mission_name}`}>{post2[9].mission_name}</Link>: {post2[9].total_runs}</li>
          </ol>
        </div>
    </div>
          );
          setPost2(postData2);
          }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const [post3, setPost3] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/top_players`)
      .then((response) => response.json())
      .then((post3) => {
        console.log(post3[0].total_players);
        console.log(post3);
        if (post3[0].run_validation == null) {
          let postData3 = (
            <div className="App">
        <div className="home-list">
        Players' Total Runs
        <ol className="list">
          <li><Link to={`/players/${post3[0].player_id}`}>{post3[0].player_name}</Link>: {post3[0].players_total_runs}</li>
          <li><Link to={`/players/${post3[1].player_id}`}>{post3[1].player_name}</Link>: {post3[1].players_total_runs}</li>
          <li><Link to={`/players/${post3[2].player_id}`}>{post3[2].player_name}</Link>: {post3[2].players_total_runs}</li>
          <li><Link to={`/players/${post3[3].player_id}`}>{post3[3].player_name}</Link>: {post3[3].players_total_runs}</li>
          <li><Link to={`/players/${post3[4].player_id}`}>{post3[4].player_name}</Link>: {post3[4].players_total_runs}</li>
          <li><Link to={`/players/${post3[5].player_id}`}>{post3[5].player_name}</Link>: {post3[5].players_total_runs}</li>
          <li><Link to={`/players/${post3[6].player_id}`}>{post3[6].player_name}</Link>: {post3[6].players_total_runs}</li>
          <li><Link to={`/players/${post3[7].player_id}`}>{post3[7].player_name}</Link>: {post3[7].players_total_runs}</li>
          <li><Link to={`/players/${post3[8].player_id}`}>{post3[8].player_name}</Link>: {post3[8].players_total_runs}</li>
          <li><Link to={`/players/${post3[9].player_id}`}>{post3[9].player_name}</Link>: {post3[9].players_total_runs}</li>
          </ol>
        </div>
    </div>
          );
          setPost3(postData3);
          }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const [post4, setPost4] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/top_classes`)
      .then((response) => response.json())
      .then((post4) => {
        console.log(post4[0].total_players);
        console.log(post4);
        if (post4[0].run_validation == null) {
          let postData4 = (
            <div className="App">
        <div className="home-list">
          Most Played Classes
          <ol className="list">
          <li><Link to={`/records/solo/Ultimate/${post4[0].class_name}`}>{post4[0].class_name}</Link>: {post4[0].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[1].class_name}`}>{post4[1].class_name}</Link>: {post4[1].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[2].class_name}`}>{post4[2].class_name}</Link>: {post4[2].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[3].class_name}`}>{post4[3].class_name}</Link>: {post4[3].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[4].class_name}`}>{post4[4].class_name}</Link>: {post4[4].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[5].class_name}`}>{post4[5].class_name}</Link>: {post4[5].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[6].class_name}`}>{post4[6].class_name}</Link>: {post4[6].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[7].class_name}`}>{post4[7].class_name}</Link>: {post4[7].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[8].class_name}`}>{post4[8].class_name}</Link>: {post4[8].class_count}</li>
          <li><Link to={`/records/solo/Ultimate/${post4[9].class_name}`}>{post4[9].class_name}</Link>: {post4[9].class_count}</li>
        </ol>
        </div>
    </div>
          );
          setPost4(postData4);
          }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const [post6, setPost6] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/unique_runs`)
      .then((response) => response.json())
      .then((post6) => {
        console.log(post6[0].total_players);
        console.log(post6);
        if (post6[0].run_validation == null) {
          let postData6 = (
            <div className="App">
        <div className="home-list">
          Most Ran Missions (Unique)
          <ol className="list">
          <li><Link to={`/missions/solo/${post6[0].mission_name}`}>{post6[0].mission_name}</Link>: {post6[0].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[1].mission_name}`}>{post6[1].mission_name}</Link>: {post6[1].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[2].mission_name}`}>{post6[2].mission_name}</Link>: {post6[2].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[3].mission_name}`}>{post6[3].mission_name}</Link>: {post6[3].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[4].mission_name}`}>{post6[4].mission_name}</Link>: {post6[4].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[5].mission_name}`}>{post6[5].mission_name}</Link>: {post6[5].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[6].mission_name}`}>{post6[6].mission_name}</Link>: {post6[6].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[7].mission_name}`}>{post6[7].mission_name}</Link>: {post6[7].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[8].mission_name}`}>{post6[8].mission_name}</Link>: {post6[8].unique_player_runs}</li>
          <li><Link to={`/missions/solo/${post6[9].mission_name}`}>{post6[9].mission_name}</Link>: {post6[9].unique_player_runs}</li>
        </ol>
        </div>
    </div>
          );
          setPost6(postData6);
          }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const [post5, setPost5] = useState();
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/recent_vods`)
      .then((response) => response.json())
      .then((post5) => {
        console.log(post5[0].mission_name);
        console.log(post5);
        
          let postData5 = (
            <div className="App">
              
        <div className="homeVideos">
        <h3>Recent Runs</h3>
              <div className="container">
                <div className="row">
                  <div className="runMedia">
                    <iframe
                      width="640"
                      height="480"
                      src={post5[0].run_validation}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                </div>
              </div>
                <div className="row">
                  <div className="runMedia">
                    <iframe
                      width="640"
                      height="480"
                      src={post5[1].run_validation}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                </div>
              </div>
            </div>
        </div>
    </div>
          );
          setPost5(postData5);
      });
  }, []); //Passing in an empty array is equvialent to component did mount!
  
  
  return (
    <div className="App">
      <div className="homeTotals">
      {post}
      <div className="homeTables">
      {post2}
      {post3}
      {post4}
      {post6}
      </div>
      {post5}
      </div>
      {/* <Table columns={columns} data={data} /> */}
      
    </div>
  );
}

export default Home;
