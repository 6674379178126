import React from "react";
import DamageCalculator from "./DamageCalculator"; // adjust your import path if needed

function CompareDamage() {
  return (
    <div style={{ display: "flex", gap: "3rem", justifyContent: "center" }}>
      {/* Left Calculator */}
      <div>
        <h2>Damage Calculator A</h2>
        <DamageCalculator calculatorId="left"/>
      </div>

      {/* Right Calculator */}
      <div>
        <h2>Damage Calculator B</h2>
        <DamageCalculator calculatorId="right"/>
      </div>
    </div>
  );
}

export default CompareDamage;
