import { Switch, Route } from "react-router-dom";
import Header from "./Header";
import Login from "./components/Login";
import Players from "./components/Players";
import PlayerSolo from "./components/PlayerSolo";
import PlayerDuo from "./components/PlayerDuo";
import PlayerTrio from "./components/PlayerTrio";
import PlayerQuad from "./components/PlayerQuad";
import PlayerParty from "./components/PlayerParty";
import Home from "./components/Home";
import RecordsSolo from "./components/RecordsSolo";
import RecordsSoloByClass from "./components/RecordsSoloByClass";
import RecordsDuo from "./components/RecordsDuo";
import RecordsTrio from "./components/RecordsTrio";
import RecordsQuad from "./components/RecordsQuad";
import RecordsParty from "./components/RecordsParty";
import Duo from "./components/Duo";
import Party from "./components/Party";
import Events from "./components/Events";
import Official from "./components/Official";
import PlayersSingle from "./components/PlayersSingle";
import RunSingle from "./components/RunSingle";
import Leaderboards from "./components/Leaderboards";
import LeaderboardsDuo from "./components/LeaderboardsDuo";
import LeaderboardsTrio from "./components/LeaderboardsTrio";
import LeaderboardsParty from "./components/LeaderboardsParty";
import Classes from "./components/Classes";
import EventSingle from "./components/EventSingle";
import CreatePlayer from "./components/CreatePlayer";
import CreateRun from "./components/CreateRun";
import Table from "./Tables/Table";
import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import MissionSingleSolo from "./components/MissionSingleSolo";
import MissionSingleDuo from "./components/MissionSingleDuo";
import MissionSingleTrio from "./components/MissionSingleTrio";
import MissionSingleQuad from "./components/MissionSingleQuad";
import MissionSingleParty from "./components/MissionSingleParty";
import DamageCalculator from "./components/DamageCalculator";
import DamageCalculatorCompare from "./components/DamageCalculatorCompare";

function App() {
  return (
    <>
      <div id="App">
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/DamageCalculator" component={DamageCalculatorCompare} exact />
          <Route path="/CreatePlayer" component={CreatePlayer} exact />
          <Route path="/CreateRun" component={CreateRun} exact />
          {/* <Route path="/records/solo" component={RecordsSolo} exact />
          <Route path="/records/duo" component={RecordsDuo} exact />
          <Route path="/records/trio" component={RecordsTrio} exact />
          <Route path="/records/party" component={RecordsParty} exact /> */}
          <Route path="/records/solo/:rank" component={RecordsSolo} exact />
          <Route path="/records/solo/:rank/:class_name" component={RecordsSoloByClass} />
          <Route path="/records/duo/:rank" component={RecordsDuo} exact />
          <Route path="/records/trio/:rank" component={RecordsTrio} exact />
          <Route path="/records/quad/:rank" component={RecordsQuad} exact />
          <Route path="/records/party/:rank" component={RecordsParty} exact />

          <Route path="/players" component={Players} exact />
          <Route path="/players/Solo" component={PlayerSolo} exact />
          <Route path="/players/Duo" component={PlayerDuo} exact />
          <Route path="/players/Trio" component={PlayerTrio} exact />
          <Route path="/players/Quad" component={PlayerQuad} exact />
          <Route path="/players/Party" component={PlayerParty} exact />
          <Route path="/Events" component={Events} exact />
          <Route path="/Official" component={Official} exact />
          <Route path="/Leaderboards/Solo" component={Leaderboards} exact />
          <Route path="/Leaderboards/Duo" component={LeaderboardsDuo} exact />
          <Route path="/Leaderboards/Trio" component={LeaderboardsTrio} exact />
          <Route
            path="/Leaderboards/Party"
            component={LeaderboardsParty}
            exact
          />
          <Route path="/Players/:id" component={PlayersSingle} exact/>
          <Route path="/Runs/:id" component={RunSingle} />
          <Route path="/Events/:id" component={EventSingle} />
          <Route path="/classes/:class_name" component={Classes} />
          <Route
            path="/missions/solo/:mission_name"
            component={MissionSingleSolo}
          />
          <Route
            path="/missions/duo/:mission_name/"
            component={MissionSingleDuo}
          />
          <Route
            path="/missions/trio/:mission_name"
            component={MissionSingleTrio}
          />
          <Route
            path="/missions/quad/:mission_name"
            component={MissionSingleQuad}
          />
          <Route
            path="/missions/party/:mission_name"
            component={MissionSingleParty}
          />
        </Switch>
      </div>
    </>
  );
}

export default App;
