import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function PlayersSingle(props) {
  const [post, setPost] = useState();
  const { id } = props.match.params;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  const [post2, setPost2] = useState();
    useEffect(() => {
      console.log(props.match);
      fetch(`https://psucta.com/api/players/${id}`)
        .then((response) => response.json())
        .then((post2) => {
          console.log(post2);
          
            let postData2 = (
              <div className="home-app">
                <img
                  src={post2[0].profile_picture}
                  style={{ width: 100, borderRadius: "50%" }}
                />
          <h3 className="home-header">{post2[0].player_name}</h3>
      </div>
            );
            setPost2(postData2);
            
        });
    }, []); //Passing in an empty array is equvialent to component did mount!
  const [post6, setPost6] = useState();
    useEffect(() => {
      console.log(props.match);
      fetch(`https://psucta.com/api/players/${id}`)
        .then((response) => response.json())
        .then((post6) => {
          console.log(post6);
          
            let postData6 = (
              <div className="home-app">
          <div className="col">
            <h4>Played Classes</h4>
            {post6.map(player_class => <div><Link to={`/records/solo/Ultimate/${player_class.class_name}`} onClick={() => window.location.href = `/records/solo/Ultimate/${player_class.class_name}`}>{player_class.class_name}</Link>: {player_class.class_count}</div>)}
          </div>
      </div>
            );
            setPost6(postData6);
            
        });
    }, []); //Passing in an empty array is equvialent to component did mount!

    const [post3, setPost3] = useState();
    useEffect(() => {
      console.log(props.match);
      fetch(`https://psucta.com/api/players/${id}/mission_count`)
        .then((response) => response.json())
        .then((post3) => {
          console.log(post3);
          
            let postData3 = (
              <div className="home-app">
                
          <div className="col">
            <h4>Mission Count</h4>
            {post3.map(mission => <div><Link to={`/missions/solo/${mission.mission_name}`} onClick={() => window.location.href = `/missions/solo/${mission.mission_name}`}>{mission.mission_name}</Link>: {mission.mission_count}</div>)}
          </div>
      </div>
            );
            setPost3(postData3);
            
        });
    }, []); //Passing in an empty array is equvialent to component did mount!
    const [post4, setPost4] = useState();
    useEffect(() => {
      console.log(props.match);
      fetch(`https://psucta.com/api/players/${id}/total_missions`)
        .then((response) => response.json())
        .then((post4) => {
          console.log(post4);
          
            let postData4 = (
              <div className="home-app">
                
          <div className="col">
            <h4>Missions completed</h4>
            Total runs: {post4[0].total_mission_count}
            {post4.map(mission => <div>{mission.player_count}P runs: {mission.mission_count}</div>)}
            
          </div>
      </div>
            );
            setPost4(postData4);
            
        });
    }, []); //Passing in an empty array is equvialent to component did mount!
    const [post5, setPost5] = useState();
    useEffect(() => {
      console.log(props.match);
      fetch(`https://psucta.com/api/players/${id}/favorite_players`)
        .then((response) => response.json())
        .then((post5) => {
          console.log(post5);
          
            let postData5 = (
              <div className="home-app">
                
          <div className="col">
            <h4>Favorite Players</h4>
            {post5.map(player => <div>Runs with <Link to={`/players/${player.favorite_player_id}`} onClick={() => window.location.href = `/players/${player.favorite_player_id}`}>{player.favorite_player_name}</Link>: {player.runs_together}</div>)}
          </div>
      </div>
            );
            setPost5(postData5);
            
        });
    }, []); //Passing in an empty array is equvialent to component did mount!

  // console.log(data);
  console.log("Count: ", data);
  useEffect(() => {
    (async () => {
      const result = await axios(
        `https://psucta.com/api/players/${id}/runs`
      );
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      {post}
      {post2}
      <div className="playerTables">
      {post6}
      {post3}
      {post4}
      {post5}
      </div>
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={`${data.length > 0 ? data[0].player_names : "Player"}'s Runs`}
          columns={[
            {
              title: "",
              field: "profile_pictures",
              width: "100%",
              render: (rowData) => (
                <img
                  src={rowData.profile_picture}
                  style={{ width: 50, borderRadius: "50%" }}
                />
              ),
              filtering: false,
              sorting: false,
              searchable: false,
            },
            {
              title: "Player Names",
              field: "player_names",
              render: (rowData) => (
                <Link to={`/players/${rowData.player_names}`}>
                  {rowData.player_names}
                </Link>
              ),
            },
            {
              title: "Mission Name",
              field: "mission_name",
              render: (rowData) => (
                <Link to={`/missions/solo/${rowData.mission_name}`}>
                  {rowData.mission_name}
                </Link>
              ),
            },
            { title: "Mission Rank", field: "rank_name" },
            { title: "Clear Time", field: "clear_time" },
            {
              title: "Class Names",
              field: "class_names",
              render: (rowData) => (
                <Link to={`/classes/${rowData.class_names}`}>
                  {rowData.class_names}
                </Link>
              ),
            },
            { title: "Race", field: "race_name" },
            {
              title: "Player Count",
              field: "player_count",
            },

            { title: "Player Level", field: "player_level" },

            { title: "Patch", field: "patch" },
            {
              title: "Validation Type",
              field: "validation_type",
              render: (rowData) => (
                <Link to={`/runs/${rowData.run_id}`}>
                  {rowData.validation_type}
                </Link>
              ),
            },
            {
              title: "Date Added",
              field: "created_date",
              type: "date",
              dateSetting: {
                format: "dd/MM/yyyy",
              },
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 500],
            pageSize: 10,
            headerStyle: { fontWeight: 700 },
            paginationType: "stepped",
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default PlayersSingle;
