import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function MissionSingle(props) {
  const [post, setPost] = useState();
  const { mission_name } = props.match.params;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const defaultExpandedRows = data.map((element, index) => {
    return { index: true };
  });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  // console.log(data);
  console.log("Count: ", data);
  useEffect(() => {
    (async () => {
      const result = await axios(
        `https://psucta.com/api/missions/${mission_name}/trio`
      );
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      {post}
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
            title={
              <div className="leaderboard-type">
                <h4>{mission_name} (Trio)</h4>
                <div className="player-count">
                <Link to={`/missions/solo/${mission_name}`}>Solo</Link>
                <Link to={`/missions/duo/${mission_name}`}>Duo</Link>
                <Link to={`/missions/trio/${mission_name}`}>Trio</Link>
                <Link to={`/missions/quad/${mission_name}`}>Quad</Link>
                <Link to={`/missions/party/${mission_name}`}>Party</Link>
                </div>
              </div>
            }
            columns={[
              {
                title: "",
                field: "profile_pictures",
                width: "100%",
                render: (rowData) => {
                  var components = rowData.profile_pictures.map(function (item) {
                    return (
                      <div key={item}>
                        <img
                          src={item}
                          style={{ width: 20, borderRadius: "50%" }}
                        />
                      </div>
                    );
                  });
                  return <div>{components}</div>;
                },
                filtering: false,
                sorting: false,
                searchable: false,
              },
              {
                title: "Player Names",
                field: "player_names",
                render: (rowData) => {
                  // Safeguard in case player_names is null/undefined
                  const playerNames = rowData.player_names || [];
                  // Map over player names to create links
                  const components = playerNames.map((item, index) => (
                    <div key={index}>
                      <Link to={`/players/${rowData.player_ids[index]}`}>{item}</Link>
                    </div>
                  ));
                  return <div>{components}</div>;
                },
              },
              {
                title: "Mission Name",
                field: "mission_name",
                render: (rowData) => (
                  <Link to={`/missions/duo/${rowData.mission_name}`}>
                    {rowData.mission_name}
                  </Link>
                ),
              },
              { title: "Clear Time", field: "clear_time" },
              {
                title: "Class Names",
                field: "class_names",
                render: (rowData) => {
                  var components = rowData.class_names.map(function (item) {
                    return (
                      <div key={item}>
                        <Link to={`/classes/${item}`}>{item}</Link>
                      </div>
                    );
                  });
                  return <div>{components}</div>;
                },
              },
              {
                title: "Races",
                field: "player_races",
                render: (rowData) => {
                  var components = rowData.player_races.map(function (item) {
                    return <div key={item}>{item}</div>;
                  });
                  return <div>{components}</div>;
                },
              },
              /* {
                title: "Player Level",
                field: "player_levels",
                render: (rowData) => {
                  var components = rowData.player_levels.map(function (item) {
                    return <div key={item}>{item}</div>;
                  });
                  return <div>{components}</div>;
                },
              }, */
  
              
              { title: "Patch", field: "patch" },
              {
                title: "Validation Type",
                field: "validation_type",
                render: (rowData) => (
                  <Link to={`/runs/${rowData.run_id}`}>
                    {rowData.validation_type}
                  </Link>
                ),
              },
              {
                title: "Date Added",
                field: "created_date",
                type: "date",
                dateSetting: {
                  format: "dd/MM/yyyy",
                },
              },
            ]}
            data={data}
            icons={tableIcons}
            defaultExpanded={defaultExpandedRows}
            defaultGroupingExpansionDepth="false"
            options={{
              filtering: true,
              paging: false,
              grouping: false,
              headerStyle: { fontWeight: 700 },
              expanded: true,
              defaultExpanded: true,
            }}
          />
      </MuiThemeProvider>
    </div>
  );
}

export default MissionSingle;
