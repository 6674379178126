import React, { useState } from "react";

function DamageCalculator({ calculatorId = "default" }) {
  // Example stats
  const [maxHP, setMaxHP] = useState(4655);    // Character Health
  const [charATP, setCharATP] = useState(2383);    // Character Attack Power
  const [weaponAtpAtZero, setWeaponAtpAtZero] = useState(1085); // Weapon Attack Power
  const [weaponATP, setWeaponATP] = useState(1300); // Weapon Attack Power
  const [enemyDFP, setEnemyDFP] = useState(630);   // Enemy Defense
  const [paModifier, setPaModifier] = useState(920);   // e.g., 120% for a certain Photon Art
  const [elementModifier, setElementModifier] = useState(50); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [atpBuffModifier, setAtpBuffModifier] = useState(1.0); // default no buff
  const [zalureDebuff, setZalureDebufffModifier] = useState(1.0); // default no buff
  const [paBoost, setPaBoostModifier] = useState(1.2); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [fullCustomPower, setFullCustomPowerModifier] = useState(0); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [weaponElementPlus, setWeaponElementPlus] = useState(0); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [rightHandPlus, setRightHandPlus] = useState(0); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [revengeBlaster, setRevengeBlaster] = useState(1.0); // Element advantage e.g., 1.2 for 20% elemental advantage
  const [justAttackDamagePlus, setJustAttackDamagePlus] = useState(0); // Element advantage e.g., 1.2 for 20% elemental advantage
  
  const shiftaLevels = [
    { label: "None", value: 1.0 },
    { label: "Lv1", value: 1.11 },
    { label: "Lv2", value: 1.15 },
    { label: "Lv3", value: 1.19 },
    { label: "Lv4", value: 1.23 },
    { label: "Lv5", value: 1.27 },
  ];

  const zalureLevels = [
    { label: "None", value: 1.0 },
    { label: "Lv1", value: 1.11 },
    { label: "Lv2", value: 1.15 },
    { label: "Lv3", value: 1.19 },
    { label: "Lv4", value: 1.23 },
    { label: "Lv5", value: 1.27 },
  ];
  const fullCustomPowerLevels = [
    { label: "None", value: 0 },
    { label: "Lv1", value: 0.10 },
    { label: "Lv2", value: 0.20 }
  ];
  const justAttackDamageLevels = [
    { label: "None", value: 0.0 },
    { label: "Lv1", value: 0.04208 },
    { label: "Lv2", value: 0.085 }
  ];
  const justCounterDamage = [
    { label: "None", value: 1.0 },
    { label: "Lv1", value: 1.10 },
    { label: "Lv2", value: 1.20 }
  ];
  const criticalMastery = [
    { label: "None", value: 1.0 },
    { label: "Lv1", value: 1.05 }
  ];
  const weaponElementPlusLevels = [
    { label: "None", value: 0 },
    { label: "Lv1", value: 3 }
  ];
  const rightHandPlusLevels = [
    { label: "None", value: 0 },
    { label: "Lv1", value: 0.02 },
    { label: "Lv2", value: 0.04 }
  ];
  const revengeBlasterLevels = [
    { label: "None", value: 1.0 },
    { label: "Lv1", value: 1.20 }
  ];
  const minRevengeBlaster = Math.min(...revengeBlasterLevels.map((b) => b.value));
  const maxRevengeBlaster = Math.max(...revengeBlasterLevels.map((b) => b.value));
  // Compute damage
  var element;
  if (elementModifier != 0){
    element = 1 + ((5+elementModifier + weaponElementPlus)/151.5);
  }
  else{
    element = 1
  }
  
  const baseDamage = Math.floor(((charATP + Math.floor(weaponAtpAtZero * rightHandPlus) + (weaponATP + weaponAtpAtZero * fullCustomPower)) / 3.7 * (paModifier / 100) * element * atpBuffModifier  * (1000 / (1000 + enemyDFP))* zalureDebuff) * revengeBlaster * paBoost);
  
  const netDamage = Math.max(baseDamage, 0);
  const critDamage = Math.floor(baseDamage * 1.5);
  const justAttackDamage = Math.floor(netDamage * (1.5 *(1 + justAttackDamagePlus)));

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto" }}>
      <div>
        <label>Character HP:</label>
        <input
          type="number"
          value={maxHP}
          onChange={(e) => setMaxHP(Number(e.target.value))}
        />
      </div>
      <div>
        <label>Character ATP:</label>
        <input
          type="number"
          value={charATP}
          onChange={(e) => setCharATP(Number(e.target.value))}
        />
      </div>

      <div>
        <label>Weapon ATP (0/10):</label>
        <input
          type="number"
          value={weaponAtpAtZero}
          onChange={(e) => setWeaponAtpAtZero(Number(e.target.value))}
        />
      </div>

      <div>
        <label>Weapon ATP (x/10):</label>
        <input
          type="number"
          value={weaponATP}
          onChange={(e) => setWeaponATP(Number(e.target.value))}
        />
      </div>

      <div>
        <label>Weapon Percentage:</label>
        <input
          type="number"
          step="1"
          value={elementModifier}
          onChange={(e) => setElementModifier(Number(e.target.value))}
        />
      </div>
      
      <div>
        <label>Weapon PA Boost:</label>
        <input
          type="number"
          value={paBoost}
          onChange={(e) => setPaBoostModifier(Number(e.target.value))}
        />
      </div>

      <div>
        <label>PA Modifier:</label>
        <input
          type="number"
          step="0.01"
          value={paModifier}
          onChange={(e) => setPaModifier(Number(e.target.value))}
        />
      </div>

      <div>
        <label>Enemy DFP:</label>
        <input
          type="number"
          value={enemyDFP}
          onChange={(e) => setEnemyDFP(Number(e.target.value))}
        />
      </div>

      

      
      <div>
        <label>Shifta Level:</label>
        <br />
        {shiftaLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`shiftaBuff-${calculatorId}`} 
              value={buff.value}
              checked={atpBuffModifier === buff.value}
              onChange={() => setAtpBuffModifier(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Zalure Level:</label>
        <br />
        {zalureLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`zalureDebuff-${calculatorId}`} 
              value={buff.value}
              checked={zalureDebuff === buff.value}
              onChange={() => setZalureDebufffModifier(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Full Custom Power:</label>
        <br />
        {fullCustomPowerLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`fcp-${calculatorId}`} 
              value={buff.value}
              checked={fullCustomPower === buff.value}
              onChange={() => setFullCustomPowerModifier(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Just Attack Damage+:</label>
        <br />
        {justAttackDamageLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`justAttackDamagePlus-${calculatorId}`} 
              value={buff.value}
              checked={justAttackDamagePlus === buff.value}
              onChange={() => setJustAttackDamagePlus(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Weapon Element+:</label>
        <br />
        {weaponElementPlusLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`weaponElementPlus-${calculatorId}`} 
              value={buff.value}
              checked={weaponElementPlus === buff.value}
              onChange={() => setWeaponElementPlus(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Right Hand+:</label>
        <br />
        {rightHandPlusLevels.map((buff) => (
          <label key={buff.value} style={{ marginRight: "1rem" }}>
            <input
              type="radio"
              name={`rightHandPlus-${calculatorId}`} 
              value={buff.value}
              checked={rightHandPlus === buff.value}
              onChange={() => setRightHandPlus(buff.value)}
            />
            {buff.label}
          </label>
        ))}
      </div>
      <div>
        <label>Revenge Blaster:</label>
        <br />
        <input
          type="range"
          min={minRevengeBlaster}
          max={maxRevengeBlaster}
          step={0.01}
          value={revengeBlaster}
          onChange={(e) => setRevengeBlaster(parseFloat(e.target.value))}
          style={{ width: "300px" }}
        />
      </div>

      <hr />

      <h2>Damage: {Math.round(netDamage)}</h2>
      <h2>Crit Damage: {Math.round(critDamage)}</h2>
      <h2>JA Damage: {Math.round(justAttackDamage)}</h2>
    </div>
  );
}

export default DamageCalculator;
